import React from 'react';
// import { useState, useEffect } from 'react';
import Navbar from './Navbar';
// import SearchBar from './SearchBar';
// import ReactDOM from "react-dom";

// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";

const Start = () => {

// Render the page
return (
    <>
    <Navbar />
    </>

    
);
};

export default Start;